import { X } from "@phosphor-icons/react";
import { type VariantProps, cva } from "class-variance-authority";
import type * as React from "react";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center justify-center px-2 py-1 rounded border-slate-200 transition-colors focus:outline-none focus:ring-2 focus:ring-slate-900 focus:ring-offset-2",
  {
    variants: {
      variant: {
        white: "bg-white border-gray-200 text-gray-500",
        orange: "bg-violet-50 border-violet-700 text-violet-900",
        green: "bg-green-50 border-green-700 text-green-900",
        blue: "bg-blue-50 border-blue-700 text-blue-900",
        yellow: "bg-yellow-50 border-yellow-700 text-yellow-900",
        rose: "bg-rose-50 border-rose-700 text-rose-900",
        grey: "border-transparent bg-slate-50 text-slate-900",
        default: "border-transparent bg-slate-100 text-slate-700 font-semibold",
      },
      size: {
        sm: "text-xs",
        lg: "text-sm",
      },
      bordered: {
        true: "border",
        false: "border-transparent",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "sm",
      bordered: true,
    },
  },
);

const statusesVariant = cva("w-1.5 h-1.5 rounded-full", {
  variants: {
    statusVariant: {
      green: "bg-green-400",
      blue: "bg-blue-400",
      yellow: "bg-yellow-400",
      grey: "bg-slate-400",
      default: "bg-slate-500",
      rose: "bg-rose-600",
    },
    defaultVariants: {
      statusVariant: "default",
    },
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants>,
    VariantProps<typeof statusesVariant> {
  status: boolean;
  onRemove?: React.MouseEventHandler<SVGSVGElement>;
}

export type BadgeVariantOptions = BadgeProps["variant"];

function Badge({
  className,
  variant,
  statusVariant,
  size,
  bordered,
  status,
  children,
  onRemove,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size, bordered }), className)}
      {...props}
    >
      <div
        className={cn(
          "w-3 h-3 justify-center items-center inline-flex",
          !status && "hidden",
        )}
      >
        <span className={cn(statusesVariant({ statusVariant }))} />
      </div>
      <span className="px-1 tracking-wider">{children}</span>
      {onRemove && <X onClick={onRemove} />}
    </div>
  );
}

export { Badge, badgeVariants };
