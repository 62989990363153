import type { ReactNode } from "react";

// @Components
import { Button } from "../button.tsx";

// @Icons
import { X as CloseIcon } from "@phosphor-icons/react";

// @Types
interface ActionBarProps {
  /**
   * The main content to display within the ActionBar.
   * This can include text, elements, or components and can be styled as desired.
   */
  children: ReactNode;
  /**
   * Callback function when the close button is pressed.
   */
  onClose(): void;
  /**
   * Custom icon for the close button.
   * By default, it renders a small CloseIcon, but you can provide any icon component.
   */
  closeIcon?: ReactNode;
}

const ActionBar = ({
  children = "Hello World",
  closeIcon = <CloseIcon size="16" />,
  onClose,
}: ActionBarProps) => {
  return (
    <div
      className={
        "absolute bottom-6 left-1/2 transform -translate-x-1/2 flex items-center justify-between p-3.5 border rounded-[6px] border-gray-200  bg-white shadow-md w-1/2 z-20 animate-slide-up"
      }
    >
      {children}
      <div className={"ml-2 border-l-[1px] border-l-gray-200"}>
        <Button
          size={"icon"}
          variant={"ghost"}
          className={
            "ml-2 transition-colors duration-200 hover:bg-gray-50 active:bg-gray-100 focus:outline-blue-700 text-gray-600"
          }
          onPress={onClose}
          aria-label={"Close"}
        >
          {closeIcon}
        </Button>
      </div>
    </div>
  );
};
export default ActionBar;
