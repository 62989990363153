import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { toast as sonnerToast } from "sonner";

// @Icons
import {
  CheckCircle,
  type Icon,
  Info,
  ShootingStar,
  Warning,
  WarningOctagon,
  X as CloseIcon,
} from "@phosphor-icons/react";

// @Types
type ToastType =
  | "success"
  | "info"
  | "warning"
  | "error"
  | "handraise"
  | "neutral";

export interface ToastProps extends VariantProps<typeof toastVariants> {
  type: ToastType;
  /**
   * title: The main title of the toast.
   */
  title: string;
  /**
   * description: The description of the toast. You can pass a string or a ReactNode.
   */
  description?: React.ReactNode;
  /**
   * dismiss: A function to dismiss the toast.
   */
  dismiss?(): void;
}

const toastVariants = cva("flex items-center", {
  variants: {
    type: {
      success: "text-green-700",
      info: "text-blue-700",
      warning: "text-yellow-700",
      error: "text-red-700",
      handraise: "text-violet-800",
      neutral: "text-white",
    },
  },
  defaultVariants: {
    type: "success",
  },
});

const toastIconMap: Partial<Record<ToastType, Icon>> = {
  success: CheckCircle,
  info: Info,
  warning: Warning,
  error: WarningOctagon,
  handraise: ShootingStar,
};

const ToastContent = ({ type, title, description, dismiss }: ToastProps) => {
  const IconComponent = toastIconMap[type];

  return (
    <div
      className={cn(
        "p-3 rounded-md shadow-md border flex flex-col text-xs",
        type === "neutral"
          ? "bg-gray-900 text-white border-gray-900"
          : "bg-white border-gray-200",
      )}
    >
      <div className={cn(toastVariants({ type }))}>
        {IconComponent && (
          <IconComponent size={16} weight={"fill"} className={"mr-3"} />
        )}
        <h3 className="font-semibold text-sm capitalize mr-4">{title}</h3>
        <Button
          variant={"ghost"}
          className={cn(
            "ml-auto h-0 text-gray-700 p-0",
            type === "neutral" && "text-white",
          )}
          onPressChange={dismiss}
        >
          <CloseIcon />
        </Button>
      </div>
      <div
        className={cn(
          IconComponent && "ml-7 text-gray-700",
          "mr-8 font-normal",
        )}
      >
        {description}
      </div>
    </div>
  );
};

const toast = (props: ToastProps | string) => {
  // If `props` is a string, treat it as the title and set description as undefined
  const title = typeof props === "string" ? props : props.title;
  const description = typeof props === "string" ? undefined : props.description;
  const type = typeof props === "string" ? "success" : props.type;

  sonnerToast.custom((t) => {
    return (
      <ToastContent
        title={title}
        description={description}
        type={type}
        dismiss={() => sonnerToast.dismiss(t)}
      />
    );
  });
};

// Add shorthand methods
toast.success = (description?: React.ReactNode) =>
  toast({ type: "success", title: "Success!", description });
toast.info = (description?: React.ReactNode) =>
  toast({ type: "info", title: "Information", description });
toast.warning = (description?: React.ReactNode) =>
  toast({ type: "warning", title: "Warning", description });
toast.error = (description?: React.ReactNode) =>
  toast({ type: "error", title: "Error", description });

export { ToastContent, toast };
export default toast;
