import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";

import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";

const tabListVariant = cva(
  ["inline-flex h-10 items-center justify-center leading-tight text-slate-500"],
  {
    variants: {
      variant: {
        small: "rounded-md bg-slate-100 p-0.5 h-7",
        default: "rounded-md bg-slate-100 p-1",
        transparant: "items-end",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const tabVariants = cva(
  [
    "px-3 inline-flex items-center justify-center whitespace-nowrap rounded-sm text-sm ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  ],
  {
    variants: {
      variant: {
        small:
          "py-0.5 px-1.5 data-[state=active]:bg-white text-slate-500 data-[state=active]:text-violet-800 data-[state=active]:shadow-sm",
        default:
          "py-1.5 data-[state=active]:bg-white text-slate-500 data-[state=active]:text-violet-800 data-[state=active]:font-medium data-[state=active]:shadow-sm",
        underline:
          "pb-3 px-2.5 data-[state=active]:text-violet-800 border-b-2 border-transparent data-[state=active]:font-medium data-[state=active]:border-violet-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabListVariant>
>(({ className, variant, ...props }, ref) => {
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(tabListVariant({ variant }), className)}
      {...props}
    />
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> &
    VariantProps<typeof tabVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabVariants({ variant }), className)}
    {...props}
  />
));

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:ring-offset-2 h-full w-full",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
