import { CheckCircle, Circle } from "@phosphor-icons/react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import React, { type ReactElement, useMemo } from "react";

import { cn } from "@/lib/utils";

type RadioJumboGroupItemProps = {
  selectedValue: any;
  icon: ReactElement;
  label: string;
  helpText: string;
  disabled?: boolean;
};

type ExtendedRadioGroupItemProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
> &
  RadioJumboGroupItemProps;

const RadioJumboGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  ExtendedRadioGroupItemProps
>(
  (
    {
      icon,
      label,
      helpText,
      className,
      disabled,
      selectedValue,
      value,
      ...props
    },
    ref,
  ) => {
    const styledIcon = useMemo(() => {
      return React.cloneElement(
        icon as React.ReactElement<{ className: string }>,
        {
          className:
            selectedValue === value ? "text-violet-700" : "text-slate-400",
        },
      );
    }, [icon, value, selectedValue]);
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          "jumbo-radio-item w-jumbo-radio h-jumbo-radio block relative rounded-md border",
          disabled
            ? "border-slate-200 cursor-default"
            : "data-[state=unchecked]:border-slate-300 data-[state=checked]:border-violet-500",
          className,
        )}
        disabled={disabled}
        value={value}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="w-5 h-5 absolute top-1 left-1">
          <CheckCircle size={16} weight="fill" className="text-violet-500" />
        </RadioGroupPrimitive.Indicator>
        {selectedValue !== value && (
          <div className="check w-5 h-5 absolute top-1 left-1">
            <Circle
              size={16}
              className={disabled ? "text-slate-300" : "text-slate-400"}
            />
          </div>
        )}
        <div
          className={cn(
            "flex p-3 flex-col justify-center items-center gap-0.5 grow shrink-0 self-stretch",
            { "opacity-50": disabled },
          )}
        >
          {styledIcon}
          <h6 className="text-sm leading-tight 500 text-slate-900">{label}</h6>
          <p className="text-xs leading-tight 500 text-slate-400">{helpText}</p>
        </div>
      </RadioGroupPrimitive.Item>
    );
  },
);

export { RadioJumboGroupItem };
