import { Button, DropdownButton } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon, Check } from "@phosphor-icons/react";
import { differenceInDays, format, subDays } from "date-fns";
import { useEffect, useState } from "react";
import React from "react";
import type { DateRange } from "react-day-picker";
import { Separator } from "./controls/separator";
import { Calendar } from "./data-display/calendar";

const quickSelectOptions = [
  { label: "Today", days: 1 },
  { label: "Last 3 days", days: 3 },
  { label: "Last 7 days", days: 7 },
  { label: "Last 30 days", days: 30 },
  { label: "Last 90 days", days: 90 },
];

export type onApplyFn = (range: {
  from?: Date;
  to?: Date;
  days?: number;
  isCustom?: boolean;
}) => void;

interface DateRangePickerProps {
  filterDays?: number;
  isCustomDaysRange?: boolean;
  onApply?: onApplyFn;
  startDate?: Date;
  endDate?: Date;
}

function DateRangePickerComponent({
  filterDays = 30,
  isCustomDaysRange,
  onApply,
  startDate,
  endDate,
}: DateRangePickerProps) {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: isCustomDaysRange ? startDate : subDays(new Date(), filterDays),
    to: endDate ?? new Date(),
  });
  const [selectedOption, setSelectedOption] = useState(quickSelectOptions[3]);
  const [tempDateRange, setTempDateRange] = useState<DateRange>(dateRange);
  const [isOpen, setIsOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    const option = quickSelectOptions.find(
      (selectOption) => selectOption.days === filterDays,
    );
    if (option) setSelectedOption(option);
  }, [filterDays]);

  useEffect(() => {
    if (isCustomDaysRange) {
      setIsCustom(true);
      handleOptionSelect(null);
      if (startDate && endDate) {
        const newRange = {
          from: startDate,
          to: endDate,
        };
        setDateRange(newRange);
        setTempDateRange(newRange);
      }
    }
  }, [isCustomDaysRange, startDate, endDate]);

  useEffect(() => {
    if (!isCustomDaysRange && !isCustom && selectedOption) {
      const newRange = {
        from: subDays(new Date(), selectedOption.days),
        to: new Date(),
      };
      setDateRange(newRange);
      setTempDateRange(newRange);
    }
  }, [isCustomDaysRange, selectedOption, isCustom]);

  const handleOptionSelect = (
    option: (typeof quickSelectOptions)[0] | null,
  ) => {
    setIsCustom(option === null);
    if (!option) return;
    setSelectedOption(option);
    onApply?.({
      days: option.days,
    });
    setIsOpen(false);
  };

  const handleDateRangeChange = (range: typeof dateRange | undefined) => {
    if (!range) return;
    setTempDateRange(range);
    setIsCustom(true);
  };

  const handleApply = () => {
    if (!tempDateRange || !tempDateRange?.to || !tempDateRange?.from) return;
    setDateRange(tempDateRange);
    setIsOpen(false);

    const days = differenceInDays(tempDateRange.to, tempDateRange.from) + 1;

    onApply?.({
      from: tempDateRange.from,
      to: tempDateRange.to,
      days,
      isCustom,
    });
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <DropdownButton
          ariaLabel="Select date range"
          icon={<CalendarIcon className="mr-2 h-4 w-4" />}
          label={
            isCustom
              ? "Custom Date Range"
              : selectedOption?.label ?? "Custom Date Range"
          }
          customLabel={
            isCustom && !isOpen
              ? `${format(
                  dateRange.from ?? new Date(),
                  "MMM d, yyyy",
                )} - ${format(dateRange.to ?? new Date(), "MMM d, yyyy")}`
              : undefined
          }
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="flex">
          <div className="border-r p-2 space-y-2 w-[150px]">
            <div className="font-medium text-sm mb-2">Quick Select</div>
            {quickSelectOptions.map((option) => (
              <Button
                key={option.label}
                onPress={() => handleOptionSelect(option)}
                variant={
                  !isCustom && selectedOption === option ? "secondary" : "ghost"
                }
                className="w-full justify-start text-sm"
                size="sm"
              >
                {option.label}
              </Button>
            ))}
            <Separator className="my-2" />
            <Button
              onPress={() => handleOptionSelect(null)}
              variant={isCustom ? "secondary" : "ghost"}
              className="w-full justify-start text-sm"
              size="sm"
            >
              Custom Range
            </Button>
          </div>
          <div
            className={cn("p-2", !isCustom && "pointer-events-none opacity-50")}
          >
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={tempDateRange.from}
              selected={tempDateRange}
              onSelect={handleDateRangeChange}
              numberOfMonths={1}
              disabled={(date) => date > new Date()}
            />
            {isCustom && (
              <div className="mt-4 flex justify-end">
                <Button size="sm" onPress={handleApply}>
                  <Check className="mr-2 h-4 w-4" />
                  Apply
                </Button>
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default React.memo(DateRangePickerComponent);
