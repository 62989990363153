import { ChromePicker } from "react-color";
import { Button } from "../controls/button";
import { Popover, PopoverContent, PopoverTrigger } from "../navigation/popover";

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

export const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="gap-2">
          <div
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: color }}
          >
            &nbsp;
          </div>
          {color}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <ChromePicker color={color} onChange={(color) => onChange(color.hex)} />
      </PopoverContent>
    </Popover>
  );
};
