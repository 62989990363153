import { Button, type ButtonSizeProps } from "../button";

// @Types
import type { FC, ReactNode } from "react";

interface ButtonGroupProps {
  primaryAction: () => void;
  primaryDataAction?: string;
  primaryIcon?: ReactNode;
  primaryLabel: string;
  secondaryAction: () => void;
  secondaryDataAction?: string;
  secondaryIcon?: ReactNode;
  secondaryLabel: string;
  size?: ButtonSizeProps;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  primaryAction,
  primaryDataAction,
  primaryIcon,
  primaryLabel,
  secondaryAction,
  secondaryDataAction,
  secondaryIcon,
  secondaryLabel,
  size,
}) => {
  return (
    <div className="flex flex-row-reverse gap-3">
      <Button
        size={size}
        onPress={primaryAction}
        type="submit"
        data-action={primaryDataAction}
      >
        {primaryIcon && <span className="mr-2">{primaryIcon}</span>}
        <span>{primaryLabel}</span>
      </Button>
      {
        <Button
          size={size}
          onPress={secondaryAction}
          variant="outline"
          data-action={secondaryDataAction}
        >
          {secondaryIcon && (
            <span className="mr-2 text-gray-600">{secondaryIcon}</span>
          )}
          <span>{secondaryLabel}</span>
        </Button>
      }
    </div>
  );
};

export default ButtonGroup;
